import keyBy from "lodash/keyBy";
import Big from "big.js";
import { PuzzlePlanFragment } from "components/companies/graphql.generated";
import { Money, PuzzleAddon, PuzzlePriceBillingInterval } from "graphql/types";

import Analytics from "lib/analytics/analytics";
import { config } from "lib/config";
import { parseCookies } from "nookies";
import { Cookies } from "lib/cookies";
import { reportError } from "lib/errors/errors";
import { NEW_SUBSCRIPTION_EVENT_DESTINATION } from "./constants/events";
import { formatMoney } from "@puzzle/utils";

export const getSubscriptionCycleLabel = (cycle: PuzzlePriceBillingInterval) => {
  return cycle === PuzzlePriceBillingInterval.Month ? "/ month" : "/ year";
};

export const getUpgradeButtonLabel = (
  plan: PuzzlePlanFragment,
  currentPlan: PuzzlePlanFragment | undefined | null
) => {
  if (plan.id === currentPlan?.id) {
    return "Current plan";
  }
  if ((currentPlan?.tier ?? 0) < plan.tier) {
    return plan.isCustom ? "Book a demo" : "Upgrade";
  }
  return `Switch to ${plan.displayName}`;
};

// Calculates the value for a plan or add-on subscriptions
// Checks if the cycle is annual or monthly and calculates it.
export const calcPrice = (
  cycle: PuzzlePriceBillingInterval,
  monthlyPrice?: Money | null,
  annualPrice?: Money | null,
  estimateMonthly?: boolean
) => {
  if (cycle === PuzzlePriceBillingInterval.Month && monthlyPrice) {
    return new Big(monthlyPrice.amount).toNumber();
  }

  if (cycle === PuzzlePriceBillingInterval.Year && annualPrice) {
    const planPriceYear = new Big(annualPrice.amount);
    return (estimateMonthly ? planPriceYear.div(12) : planPriceYear).toNumber();
  }
  return 0;
};

export const sendUpgradePlanAnalyticsEvent = (
  companyId: string,
  userId: string | undefined,
  planId: string,
  isFree: boolean
) => {
  Analytics.paidTrialUpgradePlanSelected({
    companyId,
    userId: userId ?? undefined,
    currentPlan: planId,
    previousPlan: "",
    upgradedToPremium: !isFree,
    downgradedToFree: isFree,
  });
};

export const joinWithCommasAnd = (array: string[]) => {
  if (array.length === 0) return "";
  if (array.length === 1) return array[0];
  if (array.length === 2) return `${array[0]} and ${array[1]}`;
  const joinedExceptLast = array.slice(0, array.length - 1).join(", ");
  const lastItem = array[array.length - 1];
  return `${joinedExceptLast} and ${lastItem}`;
};

export const createStripeCheckoutSessionProps = (
  companyId: string,
  plan: PuzzlePlanFragment,
  addOns: PuzzleAddon[],
  bookkeeping: boolean,
  subscriptionCycle: PuzzlePriceBillingInterval
) => {
  const planPriceId =
    subscriptionCycle === PuzzlePriceBillingInterval.Month
      ? plan.monthlyStripePriceId
      : plan.annualStripePriceId;
  if (!planPriceId) {
    throw Error(
      `Selected plan ("${plan.name}") doesn't have a price defined for the selected cycle.`
    );
  }

  let successUrl = `${config.ROOT_URL}?paymentSuccess=true&plan=${plan.displayName}&selectedPlanStripeId=${planPriceId}`;
  let cancelUrl = `${config.ROOT_URL}?paymentSuccess=false&plan=${plan.displayName}`;
  if (addOns.length > 0) {
    const addOnsParams = addOns.map((addOn) => `&addon=${addOn.displayName}`);
    successUrl += addOnsParams;
    cancelUrl += addOnsParams;
  }
  // Getting bookkeeping = true on after a payment cancelation
  // will also run the inbox workflow for additional services
  // so we just enable it for the success flow
  if (bookkeeping) {
    successUrl += `&bookkeeping=true`;
  }

  const addonPriceIds = addOns.map((addOn) => {
    const addOnPriceId =
      subscriptionCycle === PuzzlePriceBillingInterval.Month
        ? addOn.monthlyStripePriceId
        : addOn.annualStripePriceId;
    if (!addOnPriceId) {
      throw Error(
        `Selected addOn ("${addOn.name}") doesn't have a price defined for the selected cycle.`
      );
    }
    return addOnPriceId;
  });

  const stripePriceIds = [planPriceId, ...addonPriceIds];
  const cookies = parseCookies();
  const facebookClickId: string | undefined = cookies[Cookies.FacebookClickId];
  const facebookPixelId: string | undefined = cookies[Cookies.FacebookPixelId];

  return {
    companyId,
    successUrl,
    cancelUrl,
    stripePriceIds,
    facebookPixelId,
    facebookClickId,
  };
};

export const getStripeIds = (
  items: Array<PuzzlePlanFragment | PuzzleAddon>,
  cycle: PuzzlePriceBillingInterval
) => {
  return (
    items.reduce<string[]>((result, item) => {
      const stripeId =
        cycle === PuzzlePriceBillingInterval.Year
          ? item.annualStripePriceId
          : item.monthlyStripePriceId;
      if (stripeId) {
        result.push(stripeId);
      }
      return result;
    }, []) ?? []
  );
};

export const getAddonsArrayFromQueryParam = (addOns: string | string[] | undefined) => {
  if (!addOns) {
    return [];
  }

  if (Array.isArray(addOns)) {
    return addOns;
  }
  return [addOns];
};

export const sendNewSubscriptionEvent = (stripeId: string | string[]) => {
  try {
    gtag("event", "conversion", {
      send_to: NEW_SUBSCRIPTION_EVENT_DESTINATION,
      value: stripeId,
      currency: "USD",
      event_callback: () => undefined,
    });
  } catch (error: any) {
    reportError(error);
  }
};

const isAvailableForSelectedCycle = (cycle: PuzzlePriceBillingInterval, addOn: PuzzleAddon) => {
  return (
    (cycle === PuzzlePriceBillingInterval.Month && !!addOn?.monthlyStripePriceId) ||
    (cycle === PuzzlePriceBillingInterval.Year && !!addOn?.annualStripePriceId)
  );
};

export const getFilteredSelectedAddOns = (
  plan: PuzzlePlanFragment | undefined,
  cycle: PuzzlePriceBillingInterval,
  currentSelectedAddOnIds: string[]
) => {
  if (!plan) {
    return currentSelectedAddOnIds;
  }
  const addOnsMapByID = keyBy(plan?.availableAddons, "id");
  const selectedAddOns = (currentSelectedAddOnIds ?? []).reduce(
    (addOns: string[], addOnId: string) => {
      const addOn = addOnsMapByID[addOnId];

      if (addOn && isAvailableForSelectedCycle(cycle, addOn)) {
        addOns.push(addOnId);
      }

      return addOns;
    },
    []
  );
  return selectedAddOns;
};

export const formatCheckoutSummaryItem = (
  price: string | undefined,
  isApproximate: boolean | undefined,
  cycle?: PuzzlePriceBillingInterval
) => {
  if (!price) {
    return null;
  }
  const cycleStr = cycle === PuzzlePriceBillingInterval.Year ? "/ year" : "/ mo";
  return `${isApproximate ? "~ " : ""}${formatMoney({
    currency: "USD",
    amount: price,
  })} ${cycleStr}`;
};

export const getPlanBoxheadercontent = (
  isOneDollarSubscriptionEnabled: boolean | undefined,
  isCustomPlan: boolean,
  isFree: boolean
) => {
  if (isOneDollarSubscriptionEnabled && !isFree) {
    return "First month for $1, then";
  }

  if (isCustomPlan && !isFree) {
    return "Starting at...";
  }

  return undefined;
};
